/** @format */

import {Html, OrbitControls, useAnimations} from '@react-three/drei'
import {Canvas, useLoader, useThree} from '@react-three/fiber'
import * as React from 'react'
import {useRef, useState} from 'react'
import {Box3, Group, LinearToneMapping, PMREMGenerator, Vector3} from 'three'
import {RoomEnvironment} from 'three/examples/jsm/environments/RoomEnvironment'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {LoadingOutlined} from "@ant-design/icons";
import {BoxAttr} from "../../../library/basic/compose";
const isDebug = false

if (!isDebug) {
    console.log = (...x: any) => {}
}
const modelPath = 'https://aiuni.cdn.avar.cn/hand_test.glb'
interface IProps {
    src: string
    width: string
    height: string
    disableOperation?: boolean
}

const Model: React.FC<IProps & {setGap: (s: number) => void}> = ({src, setGap, width, height, disableOperation}) => {
    const group = useRef<Group | null>(null)
    const glb = useLoader(GLTFLoader, src)
    const {animations, scene: gltfScene} = glb
    const {actions, mixer} = useAnimations(animations, group)
    const {camera, scene, size, invalidate, gl} = useThree()
    const [scaleFactor, setScaleFactor] = useState(1)

    React.useEffect(() => {
        if (animations.length > 0) {
            const action = actions[animations[0].name]
            action?.reset().play()
            invalidate()
        }
    }, [src])
    React.useLayoutEffect(() => {
        const pmremGenerator = new PMREMGenerator(gl)
        pmremGenerator.compileEquirectangularShader()
        const roomEnvironment = new RoomEnvironment()
        const envMap = pmremGenerator.fromScene(roomEnvironment).texture
        scene.environment = envMap

        console.log('scene.environment', scene.environment)
        gl.toneMappingExposure = 0.6
        gl.toneMapping = LinearToneMapping
        gl.setClearColor(0x000000)
        invalidate()
        return () => {
            pmremGenerator.dispose()
        }
    }, [src])

    React.useEffect(() => {
        const box = new Box3().setFromObject(gltfScene)
        const modelSize = new Vector3()
        box.getSize(modelSize)

        const center = box.getCenter(new Vector3())
        console.log('画布尺寸:', size)
        console.log('模型尺寸:', modelSize)
        console.log('包围盒尺寸', box)
        console.log('包围盒位置', group.current?.position)
        if (group.current) {
            console.log(center)
            const pos = group.current.position
            group.current.position.set(pos.x, pos.y - center.y / 2, pos.z)
            setGap(-center.y / 2)
        }

        const minScreen = box.min.clone().project(camera)
        const maxScreen = box.max.clone().project(camera)

        const canvasWidth = size.width
        const canvasHeight = size.height

        const minX = ((minScreen.x + 1) / 2) * size.width
        const maxX = ((maxScreen.x + 1) / 2) * size.width
        const minY = ((-minScreen.y + 1) / 2) * size.height
        const maxY = ((-maxScreen.y + 1) / 2) * size.height

        const modelScreenWidth = Math.abs(maxX - minX)
        const modelScreenHeight = Math.abs(maxY - minY)

        console.log(`模型的屏幕宽度: ${modelScreenWidth}px, 高度: ${modelScreenHeight}px`)
        const scaleWidth = (canvasWidth * 0.9) / modelScreenWidth
        const scaleHeight = (canvasHeight * 0.9) / modelScreenHeight

        const scale = Math.min(scaleWidth, scaleHeight)
        setScaleFactor(scale)
        console.log('camera', camera.position)

        return () => {
            console.log('group=null')
            group.current = null
            gl.dispose()
        }
    }, [src, gltfScene, size.width, size.height])

    return (
        <group ref={group} dispose={null}>
            <primitive object={glb.scene} scale={scaleFactor} />
        </group>
    )
}

export const AnimalSimplePlayer: React.FC<IProps> = (props: IProps) => {
    const [gap, setGap] = React.useState<number>(-999)
    return (
        <React.Fragment>
            <Canvas
                frameloop='demand' //如果不是循环播放，没必要always
                style={{height: props.height, width: props.width}}
                orthographic={true}
                camera={{
                    zoom: 360,
                    far: 1000,
                    near: 0.1,
                    position: new Vector3(0.61, 0.38, 4.948),
                }}>
                <ambientLight intensity={3} color={0xffffff} />
                <pointLight position={[10, 10, 10]} intensity={2.5} color={0xffffff} />
                <directionalLight intensity={0.8 * Math.PI} position={[0.5, 0, 0.866]} />
                <React.Suspense
                    fallback={
                        <Html>
                            <LoadingOutlined style={BoxAttr.getChildrenPositionObj()}/>
                        </Html>
                    }>
                    <Model {...props} setGap={setGap} />
                </React.Suspense>
                <gridHelper args={[6, 10, 0x999999, 0x333333]} position={[0, gap, 0]} />
                <OrbitControls />
            </Canvas>
        </React.Fragment>
    )
}
